import React, { ReactFragment } from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from '../components/layout/GlobalStyles';
import { ReactComponent as Name } from '../images/svgs/girl.svg';
import { BluePanel } from '../components/Blue-Panel';

const WrapperContainer = styled.div`
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    a {
        font-weight: bold;
        color: ${colors.blue3};
    }
    .flex-payments {
        min-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
        .visa {
            position: relative;
            right: 20px;
        }
        .mastercard {
            position: relative;
            left: 20px;
        }
        img {
            border-radius: 0;
        }
    }
    iframe,
    .small-image {
        width: 100%;
        height: 250px;
        margin: 32px 0px;
    }
    img,
    iframe {
        border-radius: 15px;
    }
    h2,
    h3,
    p,
    ul {
        align-self: start;
    }

    h2 {
        color: ${colors.blue4};
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        text-transform: capitalize;
        letter-spacing: 1px;
        margin-bottom: 0px;
    }
    h3 {
        letter-spacing: 0px;
        font-weight: 600;

        font-size: 24px;
        line-height: 35px;
        margin: 32px 0 0;
    }
    ul {
        padding-left: 45px;
    }
    p,
    li {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 152.5%;
    }
    ol {
        list-style: none;
        position: relative;
        right: 30px;
        counter-reset: step-counter;
        section {
            display: block;
            position: relative;
            top: 15px;
        }
        li {
            display: flex;
            counter-increment: step-counter;
        }

        li::before {
            content: counter(step-counter);
            margin-right: 20px;
            font-size: 80%;
            background-color: #beecff;
            color: ${colors.blue3};
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 20px;
            position: relative;
            font-weight: bold;
            padding: 10px;
            width: 25px;
            border-radius: 100px;
        }
    }

    .arrow {
        transition: 0.2s ease-in;
        position: relative;
        left: 12px;
        fill: ${colors.yellow3};
    }
    .flex-payments {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        width: 80%;
        gap: 15px;
        img {
            height: 38px;
            width: 100%;
        }
    }
    button:hover {
        .arrow {
            position: relative;

            fill: white;
        }
    }
    .flex-lists {
        ul {
            padding-left: 32px;
        }
    }
    @media ${device.laptop} {
        ol {
            position: relative;
            right: 0px;
        }
        .flex-lists {
            display: flex;
            justify-content: start;
            width: 100%;
            ul {
                width: 512px;
                padding-left: 32px;
            }
            ul:first-of-type {
                margin-right: 64px;
            }
        }
        .flex-payments {
            margin: 72px 0px;
            justify-content: space-between;
            img {
                height: 50px;
                width: 100%;
            }
        }
        .flex-dr {
            flex-direction: row-reverse;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 1064px;
            margin: 72px;
            p {
                width: 546px;
                font-weight: 400;
                font-size: 18px;
                line-height: 152.5%;
            }
            .doctor-image {
                height: 622px;
                width: 478px;
                img {
                    height: 622px;
                    width: 478px;
                }
            }
        }
        .small-image {
            width: 838px;
            height: 425px;
            img {
                width: 838px;
                height: 425px;
            }
        }

        iframe {
            width: 838px;
            height: 425px;
            margin: 32px 0px;
        }
    }
    @media ${device.desktop} {
        .flex-payments {
            margin: 72px 0px;
            img {
                height: 60px;
            }
        }
    }
`;

export const TemplateWrapper = ({ children }: any) => {
    return (
        <WrapperContainer>
            <div className="container">{children}</div>
            <BluePanel></BluePanel>
        </WrapperContainer>
    );
};
