import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { ReactComponent as Arrow } from '../images/svgs/arrow-right-yellow.svg';
import { ButtonPrimary } from './layout/StyledComponents';
import { ReactComponent as Girl } from '../images/svgs/girl.svg';
import { ReactComponent as Boy } from '../images/svgs/boy.svg';

const BluePanelContainer = styled.div`
    margin-top: 32px;
    position: relative;
    > svg {
        position: absolute;
        display: none;
    }

    div {
        width: 100%;
        background: ${colors.blue4};
        box-shadow: 0px 100px 83px rgba(10, 140, 201, 0.09),
            0px 40.5338px 29.478px rgba(10, 140, 201, 0.0565525),
            0px 19.9933px 16.0287px rgba(10, 140, 201, 0.051542),
            0px 8.22659px 7.61185px rgba(10, 140, 201, 0.0407491);
        border-radius: 15px;
        padding: 16px;
    }
    button {
        display: flex;
        justify-content: center;
        margin-top: 16px;
        font-size: 18px;
        width: 100%;
        &:hover {
            svg {
                left: 22px;
                fill: ${colors.yellow3};
            }
        }
        svg {
            transition: 0.2s ease-in;
            position: relative;
            left: 18px;
            width: 20px;
            fill: white;
        }
    }
    @media ${device.tablet} {
        > div {
            padding: 40px 64px;
        }
        > svg {
            display: block;
        }
        > svg:first-of-type {
            top: -52px;
            width: 112px;
            right: 0px;
        }
        > svg:last-of-type {
            bottom: -32px;
            width: 90px;
            left: -15px;
        }
    }
    @media ${device.laptop} {
        max-width: 996px;
        margin-top: 76px;
        margin-bottom: -64px;
        > div {
            padding: 40px 64px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            button {
                width: 295px;
                height: 56px;
            }
        }
        > svg:first-of-type {
            top: -52px;

            right: 60px;
        }
        > svg:last-of-type {
            bottom: -32px;

            left: -35px;
        }
    }
`;

const Text = styled.section`
    p {
        margin: 0;
    }
    p:first-of-type {
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 12px;
        text-transform: capitalize;
        color: ${colors.white};
    }
    p:last-of-type {
        font-size: 18px;
        line-height: 152.5%;
        color: ${colors.blue2};
    }
    @media ${device.laptop} {
        width: 509px;
        p:first-of-type {
            font-size: 32px;
            margin-bottom: 26px;
        }
    }
`;

export const BluePanel = () => {
    return (
        <BluePanelContainer className="container">
            <Girl />
            <div>
                <Text>
                    <p>Make the earliest appointment</p>
                    <p>No matter what your smile needs may be, we would love to hear from you!</p>
                </Text>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://patientportal-cs4.carestack.com/?dn=ck&ln=1/#/online-appointments"
                >
                    <ButtonPrimary fill>
                        Make An Appointment{''}
                        <Arrow />
                    </ButtonPrimary>
                </a>
            </div>
            <Boy />
        </BluePanelContainer>
    );
};
